/* eslint-disable */
/** API calls */

import axios from "axios";
const CryptoJS = require('crypto-js');

const BASE_URL = "https://scitox-api.scialgo.com";

import { getKey } from "./keys";

export function getAPIKey() {
    return getKey("API_KEY");
}

async function getStdHeader() {
    return {
        "api_key": getAPIKey(),
    };
}

async function getHeader() {
    try {
        let decryptUser = CryptoJS.AES.decrypt(localStorage.getItem("scitoxUser"), getKey("APP_KEY")).toString(CryptoJS.enc.Utf8);
        let scitoxUser = JSON.parse(decryptUser);

        //console.log('getHeader - user', scitoxUser);

        return {
            "api_key": getAPIKey(),
            "user_id": scitoxUser.user_id,
            "auth_token": scitoxUser.auth.token,
            "session_id": scitoxUser.auth.session
        };
    } catch (err) {
        return {
            "api_key": getAPIKey(),
        };
    }
}

export async function login(cred) {
    // console.log('LOGIN:', cred);
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/login', cred, { headers: theHeaders });
}

export async function signupUser(options) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/create', options, { headers: theHeaders });
}

export async function logout() {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/logout', {}, { headers: theHeaders });
}

export async function createClient(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/create', options, { headers: theHeaders });
}

export async function listClient(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/list', options, { headers: theHeaders });
}

export async function listState(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/state-list', options, { headers: theHeaders });
}

export async function addClientAddress(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/add-address', options, { headers: theHeaders });
}

export async function deleteClientAddress(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/delete-address', options, { headers: theHeaders });
}

export async function updateClientAddress(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/update-address', options, { headers: theHeaders });
}

export async function addClientContract(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/add-contract', options, { headers: theHeaders });
}

export async function addClientContactPerson(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/add-contact-person', options, { headers: theHeaders });
}

export async function deleteClientContactPerson(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/delete-contact-person', options, { headers: theHeaders });
}

export async function getClientContactPerson(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/client/get-contact-person', options, { headers: theHeaders });
}

export async function createOrder(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/order/create', options, { headers: theHeaders });
}

export async function fetchOrder(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/order/fetch', options, { headers: theHeaders });
}

export async function updateOrder(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/order/update', options, { headers: theHeaders });
}

export async function listClientOrders(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/order/list', options, { headers: theHeaders });
}

export async function addOrderMolecules(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/order/add-molecule', options, { headers: theHeaders });
}

export async function deleteOrderMolecules(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/order/delete-molecule', options, { headers: theHeaders });
}

export async function updateOrderMolecules(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/order/update-molecule', options, { headers: theHeaders });
}

export async function generateReport(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/report/create', options, { headers: theHeaders });
}

export async function generateMoleculewiseReport(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/report/create-single-molecule', options, { headers: theHeaders });
}

export async function getDownloadReportURL(options) {
    var theHeaders = await getHeader();
    return BASE_URL + '/api/v1/report/download/' + options.order_id + '/' + options.client_id + '/' + theHeaders.api_key + '/' + theHeaders.user_id + '/' + theHeaders.auth_token + '/' + theHeaders.session_id;
}

export async function getDownloadZipReportURL(options) {
    var theHeaders = await getHeader();
    return BASE_URL + '/api/v1/report/download-zip/' + options.order_id + '/' + options.client_id + '/' + theHeaders.api_key + '/' + theHeaders.user_id + '/' + theHeaders.auth_token + '/' + theHeaders.session_id;
}

export async function checkReportZipFile(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/reprot/report-zip-exist', options, { headers: theHeaders });
}

export async function reportUpload(options) {
    console.log('options:', options);
    var theHeaders = await getHeader();
    theHeaders['Content-Type'] = 'multipart/form-data';

    var reportData = new FormData();
    reportData.append('client_id', options['client_id']);
    reportData.append('order_id', options['order_id']);
    reportData.append('report_content', options['report_content'][0]);

    console.log('reportData:', reportData);

    return axios.post(BASE_URL + '/api/v1/report/upload', reportData, { headers: theHeaders });
}

export async function generateInvoice(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/invoice/create', options, { headers: theHeaders });
}

export async function listInvoices(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/invoice/list', options, { headers: theHeaders });
}

export async function updateInvoicePayment(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/invoice/update-payment', options, { headers: theHeaders });
}

export async function fetchInvoicePayment(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/invoice/fetch-payment', options, { headers: theHeaders });
}

export async function getDownloadInvoiceURL(options) {
    var theHeaders = await getHeader();
    return BASE_URL + '/api/v1/invoice/download/' + options.invoice_number + '/' + theHeaders.api_key + '/' + theHeaders.user_id + '/' + theHeaders.auth_token + '/' + theHeaders.session_id;
}

export async function generateProformaInvoice(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/invoice/proforma', options, { headers: theHeaders });
}

export async function listProformaInvoices(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/invoice/list-proforma', options, { headers: theHeaders });
}

export async function getDownloadProformaInvoiceURL(options) {
    var theHeaders = await getHeader();
    return BASE_URL + '/api/v1/invoice/download-proforma/' + options.invoice_number + '/' + theHeaders.api_key + '/' + theHeaders.user_id + '/' + theHeaders.auth_token + '/' + theHeaders.session_id;
}

export async function getMonthwiseRevenue(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/dashboard/monthwise-revenue', options, { headers: theHeaders });
}

export async function getClientwiseRevenue(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/dashboard/clientwise-revenue', options, { headers: theHeaders });
}

export async function getYearwiseTDS(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/dashboard/yearwise-tds', options, { headers: theHeaders });
}

export async function getPendingTasks(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/dashboard/pending-activities', options, { headers: theHeaders });
}

export async function getPendingTDSInvoices(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/invoice/pending-tds-invoices', options, { headers: theHeaders });
}