import Vue from 'vue'
import Vuex from 'vuex'

import { getKey } from "../services/keys.js"; 

const CryptoJS = require('crypto-js');

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      user: {},
  },
  mutations: {
    adduser(state, userData) {
      console.log('Adduser Mutation:', userData);
      state.user = userData;
      // save to local storage
      const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(state.user), getKey("APP_KEY")).toString();
      localStorage.setItem("scitoxUser", encryptedUser);

    },
    loaduser(state) {
      try {
        let decryptUser = CryptoJS.AES.decrypt(localStorage.getItem("scitoxUser"), getKey("APP_KEY")).toString(CryptoJS.enc.Utf8);
        state.user = JSON.parse(decryptUser);
      } catch(err) {
        state.user = {};
      }
    },
    removeuser(state) {
      try {
        localStorage.removeItem("scitoxUser");
        state.user = {};

      } catch(err) {
        state.user = {};
      }
    },
  },
  actions: {
    adduser(context, userData) {
      console.log('Adduser Action:', userData);
      context.commit('adduser', userData);
    },
    loaduser(context) {
      context.commit('loaduser');
    },
    deleteuser(context) {
      context.commit('removeuser');
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  modules: {},
})
