/* eslint-disable */ 
/** various keys used in the system */

const keys = {
    "API_KEY": 'c06e5c220acb05ddb119c13d40c931a1',
    "APP_KEY": '640769e56c67109f2a229b0cf93046d7',
};

export function getKey(keyType) {
    return keys[keyType];
}
