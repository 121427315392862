/* eslint-disable */
/** utility function */

const APP_VERSION = "0.0.2";

const urlRegex = /([http?|map?|upi?|tel?|mail?|sms?]+:\/\/[^\s]+)/g;

export function isValidEmail(email) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function appVersion() {
    return APP_VERSION;
}

export function maxAllowedCoverImages() {
    return 4;
}

export function isUserLoggedin(store) {
    let user = store.getters.user;
    //console.log('Loggedin User:', user);
    return Object.keys(user).length > 0;
}

export function getLoggedinUser(store) {
    let user = store.getters.user;
    //console.log('Loggedin User user_id:', user.user_id);
    //console.log('Loggedin User:', user.name);
    return user = store.getters.user;
}

var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export function formatDate(dateStr) {
    var dateObj = new Date(dateStr);

    var hours = dateObj.getHours();
    if (hours < 10) hours = "0" + hours;

    var minutes = dateObj.getMinutes();
    if (minutes < 10) minutes = "0" + minutes;

    var monthName = months[dateObj.getMonth()];

    var newDateFormat = monthName + " " + dateObj.getDate() + " " + dateObj.getFullYear() + ", " + hours + ":" + minutes;

    return newDateFormat;
}

export function simpleFormatDate(dateStr) {
    var dateObj = new Date(dateStr);
    var monthName = shortMonths[dateObj.getMonth()];

    var newDateFormat = monthName + " " + dateObj.getDate() + ", " + dateObj.getFullYear();

    return newDateFormat;
}

export function DDMMYYYDate(dateStr, seperator="/") {
    var dateObj = new Date(dateStr);

    var newDateFormat = (dateObj.getDate()<10? '0'+dateObj.getDate(): dateObj.getDate()) + seperator +
                        (dateObj.getMonth()<9? '0'+(dateObj.getMonth()+1) : (dateObj.getMonth()+1)) + seperator + 
                        dateObj.getFullYear();

    return newDateFormat;
}

export function openExternalURL(url) {
    window.open(url);
}

export function getCurrentFinancilaYear(url) {
    //current financial year
    let today = new Date();
    let year1 = today.getFullYear();
    let year2 = year1 + 1;
    let month = today.getMonth()+1;
    if (month < 4) {
    year2 = year1;
    year1 = year2 - 1;
    }
    let finYear = year1 + '-' + year2;

    return finYear;
}
